<template>
  <b10-base>
    <template
      v-if="isInsert && !desdeOT"
    >
      <v-alert
        v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
        color="warning"
        dark
        small
      >
        Se creará un nuevo subsistema
      </v-alert>
      <b10-autocomplete
        v-model="form.idsubsis_idtsubsis"
        :items="subsisTsubsis"
        :rules="formRules.idsubsis_idtsubsis"
        item-value="value"
        item-text="descripcion"
        label="Subsistema"
        clearable
        @change="subsisTsubsisSelected"
      >
        <template
          #item="{ item, attrs, on }"
        >
          <v-list-item
            v-bind="attrs"
            :disabled="item.value.tipo === 'divider'"
            v-on="on"
          >
            <v-list-item-content
              v-if="item.value.tipo === 'divider'"
              :class="item.value.color"
              class="white--text pa-1 rounded"
            >
              {{ item.descripcion }}
            </v-list-item-content>
            <v-list-item-content
              v-else
            >
              {{ item.descripcion }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </b10-autocomplete>
      <v-text-field
        v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
        v-model="form.subsis_codigo"
        clearable
        flat
        label="Código del subsistema"
        :rules="formRules.subsis_codigo"
      />
      <v-text-field
        v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
        v-model="form.subsis_descripcion"
        clearable
        flat
        label="Descripción del subsistema"
        :rules="formRules.subsis_descripcion"
      />
    </template>
    <b10-autocomplete
      v-if="showTinclusion && !desdeOT"
      v-model="form.tinclusion"
      :items="tinclusion"
      item-value="id"
      item-text="descripcion"
      label="Tipo de inclusión"
      clearable
      :rules="formRules.tinclusion"
    />
    <v-checkbox
      v-model="form.facturar"
      label="Material facturable"
      :disabled="!hasPermModificarMaterialFacturable"
    />
    <b10-autocomplete
      v-if="!form.facturar"
      v-model="form.idtmotivo_nofacturable"
      :items="tmotivoNofacturable"
      item-value="tmotivo_nofacturable.idtmotivo_nofacturable"
      item-text="tmotivo_nofacturable.descripcion"
      label="Motivo de no facturación"
      :rules="formRules.idtmotivo_nofacturable"
      clearable
    />
    <v-text-field
      v-if="!desdeOT"
      v-model="form.descripcion"
      clearable
      label="Descripción"
      :disabled="articulo.articulo && !articulo.articulo.modificar_desc_movilidad"
    />
    <v-text-field
      v-model.number="form.unidades"
      label="Unidades"
      clearable
      type="number"
    />
    <v-checkbox
      v-if="isInsert && !desdeOT"
      v-show="form.unidades > 1"
      v-model="form.desglosar"
      label="Desglosar unidades"
    />
    <template
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_nserie && !isInsert"
    >
      <template
        v-if="articulo.articulo.sin_stock === stock.porNserie"
      >
        <b10-autocomplete
          v-model="form.nserie"
          :items="lstock"
          item-text="lstock.nserie"
          label="Nª serie"
          :rules="formRules.nserie"
          :append-outer-icon="$vuetify.icons.values.camera"
          clearable
          @click:append-outer="clickNserieQR"
        />
      </template>
      <template
        v-else
      >
        <v-text-field
          v-model="form.nserie"
          clearable
          label="Nº serie"
          :rules="formRules.nserie"
          :messages="nserieMessages"
          :append-outer-icon="$vuetify.icons.values.camera"
          @click:append-outer="clickNserieQR"
        />
      </template>
    </template>
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_nelemento && !isInsert"
      v-model="form.nelemento"
      clearable
      label="Nº elemento"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_ubicacion && !isInsert"
      v-model="form.ubicacion"
      clearable
      label="Ubicación"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_netiqueta && !isInsert"
      v-model="form.netiqueta"
      clearable
      label="Nº etiqueta"
      :append-outer-icon="$vuetify.icons.values.camera"
      @click:append-outer="clickNetiquetaQR"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_num_zona && !isInsert"
      v-model="form.numzona"
      clearable
      label="Nº zona"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_evento && !isInsert"
      v-model="form.evento"
      clearable
      label="Evento"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_funcion && !isInsert"
      v-model="form.funcion"
      clearable
      label="Función"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_particion && !isInsert"
      v-model="form.particion"
      clearable
      label="Partición"
    />
    <!-- fultrevision (no debe ser editable) -->
    <!-- fprox_revision (no existe aún en offline, es un campo calculado en el servidor) -->
    <b10-date-picker
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_fult_retimbrado && !isInsert"
      v-model="form.fult_retimbrado"
      title="Último retimbrado"
    />
    <!-- fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor) -->
    <!-- <b10-date-picker
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_fcaducidad && !isInsert"
      v-model="form.fcaducidad"
      title="Caducidad"
    ></b10-date-picker> -->
    <!-- <b10-date-picker
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_ffin_garantia && !isInsert"
      v-model="form.ffin_garantia"
      title="Fin de garantía"
    ></b10-date-picker> -->
    <b10-autocomplete
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_idfabricante && !isInsert"
      v-model="form.idfabricante"
      :items="fabricantes"
      item-value="fabricante.idfabricante"
      item-text="fabricante.descripcion"
      label="Fabricante"
      clearable
    />
    <b10-date-picker
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_ffabricacion && !isInsert"
      v-model="form.ffabricacion"
      title="Fabricación"
    />
    <b10-autocomplete
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_idagente_extintor && !isInsert"
      v-model="form.idagente_extintor"
      :items="agentesExtintores"
      item-value="agente_extintor.idagente_extintor"
      item-text="agente_extintor.descripcion"
      label="Agente extintor"
      clearable
    />
    <b10-autocomplete
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_idagente_propelente && !isInsert"
      v-model="form.idagente_propelente"
      :items="agentesPropelentes"
      item-value="agente_propelente.idagente_propelente"
      item-text="agente_propelente.descripcion"
      label="Agente propelente"
      clearable
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_volumen && !isInsert"
      v-model.number="form.volumen"
      clearable
      label="Volumen"
      type="Number"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_peso_total && !isInsert"
      v-model.number="form.peso_total"
      clearable
      label="Peso total"
      type="Number"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_peso_agente_extintor && !isInsert"
      v-model.number="form.peso_agente_extintor"
      clearable
      label="Peso agente extintor"
      type="Number"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_eficacia && !isInsert"
      v-model="form.eficacia"
      clearable
      label="Eficacia"
    />
    <v-text-field
      v-if="tsubsis.tsubsis && tsubsis.tsubsis.ver_mat_presion && !isInsert"
      v-model.number="form.presion"
      clearable
      label="Presión"
      type="Number"
    />
    <b10-textarea
      v-if="!isInsert"
      v-model="form.observaciones"
      label="Observaciones"
    />
    <b10-qr-reader
      :showing.sync="showingQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { get } from 'vuex-pathify'
import Data from './LparteTrabajoFormData'
import { TINCLUSION, STOCK } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idparteTrabajo: {
      type: [Number, String],
      required: true,
    },
    idarticulo: {
      type: [Number, String],
      required: true,
    },
    idsubsis: {
      type: [Number, String],
      default: '',
    },
    unidades: {
      type: Number,
      default: 1,
    },
    facturar: {
      type: Boolean,
      default: true,
    },
    idtmotivoNofacturable: {
      type: Number,
      default: 0,
    },
    desdeOT: {
      type: Boolean,
      default: false,
    },
    hasPermVerTarifas: {
      type: Boolean,
      default: false,
      required: true,
    },
    hasPermModificarMaterialFacturable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        idsubsis_idtsubsis: null,
        subsis_codigo: null,
        subsis_descripcion: null,
        tinclusion: null,
        facturar: true,
        idtmotivo_nofacturable: null,
        descripcion: null,
        unidades: 1,
        desglosar: true,
        nserie: null,
        nelemento: null,
        ubicacion: null,
        netiqueta: null,
        numzona: null,
        evento: null,
        funcion: null,
        particion: null,
        idfabricante: null,
        ffabricacion: null,
        idagente_extintor: null,
        idagente_propelente: null,
        volumen: null,
        peso_total: null,
        peso_agente_extintor: null,
        eficacia: null,
        presion: null,
        // fultrevision: null, (no debe ser editable)
        // fprox_revision: null, (no existe aún en offline, es un campo calculado en el servidor)
        fult_retimbrado: null,
        // fprox_retimbrado: null, (no existe aún en offline, es un campo calculado en el servidor)
        // fcaducidad: null,
        // ffin_garantia: null,
        observaciones: null,
      },
      showTinclusion: false,
      tinclusion: [
        { id: TINCLUSION.instalacion, descripcion: 'Instalación' },
        { id: TINCLUSION.suministro, descripcion: 'Suministro' },
      ],
      tmotivoNofacturable: [],
      parteTrabajo: {},
      ordenTrabajo: {},
      fabricantes: [],
      agentesExtintores: [],
      agentesPropelentes: [],
      tsubsis: {},
      articulo: {},
      subsisTsubsis: [],
      inCodigosExistentes: [],
      showingQR: false,
      targetFieldQR: '',
      stock: STOCK,
      lstock: [],
    }
  },
  computed: {
    formRules () {
      const rules = {
        idsubsis_idtsubsis: [
          v => !!v || 'Campo requerido',
        ],
        subsis_codigo: [
          v => !!v || 'Campo requerido',
          v => {
            return !this.inCodigosExistentes.includes(v) || 'Código de subsistema duplicado'
          }
        ],
        subsis_descripcion: [
          v => !!v || 'Campo requerido',
        ],
      }
      if (!this.form.facturar) {
        rules.idtmotivo_nofacturable = [
          v => !!v || 'Campo requerido'
        ]
      }
      if (this.isInsert) {
        rules.tinclusion = [
          v => !!v || 'Campo requerido'
        ]
      }
      if (this.articulo.articulo?.nserie_obligado) {
        rules.nserie = [
          v => !!v || 'Campo requerido'
        ]
      }
      return rules
    },
    formFacturar () {
      return this.form.facturar
    },
    formIdsubsisIdtsubsis () {
      return this.form.idsubsis_idtsubsis
    },
    nserieMessages () {
      if (this.articulo.articulo?.nserie_obligado) {
        return 'El nº de serie es obligatorio para este tipo de artículos'
      } else {
        return ''
      }
    },
    usuarioIdalmacen: get('usuario/idalmacen'),
  },
  watch: {
    formFacturar (newValue) {
      if (newValue) {
        this.$set(this.form, 'idtmotivo_nofacturable', null)
      }
    },
    async formIdsubsisIdtsubsis (newValue) {
      if (newValue?.idtsubsis) {
        this.tsubsis = await this.$offline.tsubsis.row(newValue.idtsubsis)
      }
    },
  },
  async created () {
    this.fabricantes = await Data.selectFabricantes(this)
    this.agentesExtintores = await Data.selectAgentesExtintores(this)
    this.agentesPropelentes = await Data.selectAgentesPropelentes(this)
    this.parteTrabajo = await Data.selectParteTrabajo(this, this.idparteTrabajo)
    this.ordenTrabajo = await this.$offline.ordenTrabajo.row(this.parteTrabajo.parte_trabajo.idorden_trabajo)
    this.tmotivoNofacturable = await Data.selectTmotivoNofacturable(this)
    this.articulo = await this.$offline.articulo.row(this.idarticulo)
    if (this.articulo.articulo.sin_stock === this.stock.porNserie) {
      this.lstock = await Data.selectLstock(
        this,
        this.id,
        this.articulo.articulo.idarticulo,
        this.parteTrabajo.parte_trabajo.idparte_trabajo,
        this.usuarioIdalmacen,
      )
    }
    if (this.isInsert) {
      this.inCodigosExistentes = await Data.selectCodigosSubsis(this, this.ordenTrabajo.orden_trabajo.idsistema)
      await this.mostrarOcultarTipoInclusion()
      this.subsisTsubsis = await this.$offline.ordenTrabajoSubsis.selectSubsisTsubsis(
        this.parteTrabajo.parte_trabajo.idorden_trabajo
      )
      this.$set(this.form, 'desglosar', this.articulo.articulo.desglosar_ubicacion)
      // seleccionar como subsistema por defecto
      // 1) si se pasó propiedad idsubsis
      if (this.idsubsis) {
        this.$set(this.form, 'idsubsis_idtsubsis', this.findSubsisIdsubsis(this.idsubsis))
      } else {
        // 2) si el sistema tiene un subsistema que coincide con tsubfamilia.idtsubsis del artículo
        const subfamilia = await this.$offline.subfamilia.row(this.articulo.articulo.idsubfamilia)
        const tsubfamilia = await this.$offline.tsubfamilia.row(subfamilia.subfamilia.idtsubfamilia)
        const subsis = this.findSubsisIdtsubsis(tsubfamilia.tsubfamilia.idtsubsis)
        if (subsis) {
          this.$set(this.form, 'idsubsis_idtsubsis', subsis)
        } else {
          // 3) tipo de subsistema que coincide con tsubfamilia.idtsubsis del artículo
          const tsubsisComportamientoSubfam = this.findTsubsisIdtsubsis(tsubfamilia.tsubfamilia.idtsubsis)
          if (tsubsisComportamientoSubfam) {
            this.$set(this.form, 'idsubsis_idtsubsis', tsubsisComportamientoSubfam)
          }
        }
      }
      this.$set(this.form, 'descripcion', this.articulo.articulo.descripcion)
      if (this.desdeOT) {
        this.$set(this.form, 'unidades', this.unidades)
        this.$set(this.form, 'facturar', this.facturar)
        this.$set(this.form, 'idtmotivo_nofacturable', this.idtmotivoNofacturable)
      }
      // forzar evento
      this.subsisTsubsisSelected()
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      // solo permite modificar subsistema al insertar
      this.$set(this.form, 'idsubsis', formData.idsubsis)
      // solo permite modificar tipo de inclusión al insertar
      this.$set(this.form, 'tinclusion', null)
      this.$set(this.form, 'facturar', formData.facturar)
      this.$set(this.form, 'idtmotivo_nofacturable', formData.idtmotivo_nofacturable)
      this.$set(this.form, 'descripcion', formData.descripcion)
      this.$set(this.form, 'unidades', formData.unidades)
      // solo permite desglosar al insertar
      this.$set(this.form, 'desglosar', false)
      // datos de material (solo al editar)
      this.$set(this.form, 'nserie', formData.nserie)
      this.$set(this.form, 'nelemento', formData.nelemento)
      this.$set(this.form, 'ubicacion', formData.ubicacion)
      this.$set(this.form, 'netiqueta', formData.netiqueta)
      this.$set(this.form, 'numzona', formData.numzona)
      this.$set(this.form, 'evento', formData.evento)
      this.$set(this.form, 'funcion', formData.funcion)
      this.$set(this.form, 'particion', formData.particion)
      this.$set(this.form, 'idfabricante', formData.idfabricante)
      this.$set(this.form, 'ffabricacion', formData.ffabricacion)
      this.$set(this.form, 'idagente_extintor', formData.idagente_extintor)
      this.$set(this.form, 'idagente_propelente', formData.idagente_propelente)
      this.$set(this.form, 'volumen', formData.volumen)
      this.$set(this.form, 'peso_total', formData.peso_total)
      this.$set(this.form, 'peso_agente_extintor', formData.peso_agente_extintor)
      this.$set(this.form, 'eficacia', formData.eficacia)
      this.$set(this.form, 'presion', formData.presion)
      // fultrevision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      this.$set(this.form, 'fult_retimbrado', formData.fult_retimbrado)
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      // fcaducidad
      // ffin_garantia
      this.$set(this.form, 'observaciones', formData.observaciones)
      // tsubsis desde el subsistema
      const subsis = await this.$offline.subsis.row(formData.idsubsis)
      this.tsubsis = await this.$offline.tsubsis.row(subsis.subsis.idtsubsis)
    },
    async mostrarOcultarTipoInclusion () {
      if (this.isInsert) {
        this.tinclusion = await this.$offline.articulo.tinclusionArticulo(
          this.articulo.articulo.codigo,
          this.parteTrabajo.orden_trabajo.idttarifa,
          this.ordenTrabajo.orden_trabajo.idbanco_precio,
          this.hasPermVerTarifas
        )
        const preguntar = await this.$offline.articulo.preguntarTinclusion(
          this.articulo.articulo.codigo,
          this.parteTrabajo.orden_trabajo.idttarifa,
          this.ordenTrabajo.orden_trabajo.idbanco_precio
        )
        this.showTinclusion = preguntar.preguntar
        if (preguntar.tinclusion) {
          this.$set(this.form, 'tinclusion', preguntar.tinclusion)
        }
      } else {
        this.showTinclusion = false
      }
    },
    subsisTsubsisSelected () {
      if (
        this.form.idsubsis_idtsubsis &&
        this.form.idsubsis_idtsubsis.codigoPorDefecto &&
        this.form.idsubsis_idtsubsis.descripcionPorDefecto
      ) {
        this.$set(this.form, 'subsis_codigo', this.form.idsubsis_idtsubsis.codigoPorDefecto)
        this.$set(this.form, 'subsis_descripcion', this.form.idsubsis_idtsubsis.descripcionPorDefecto)
      } else {
        this.$set(this.form, 'subsis_codigo', null)
        this.$set(this.form, 'subsis_descripcion', null)
      }
    },
    findSubsisIdsubsis (idsubsis) {
      const indexSubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            // idsubsis string -> se puede insertar offline
            id: idsubsis.toString(),
          }
        }
      )
      if (indexSubsis > -1) {
        return this.subsisTsubsis[indexSubsis].value
      }
    },
    findSubsisIdtsubsis (idtsubsis) {
      const indexSubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            tipo: 'subsis',
            idtsubsis,
          }
        }
      )
      if (indexSubsis > -1) {
        return this.subsisTsubsis[indexSubsis].value
      }
    },
    findTsubsisIdtsubsis (idtsubsis) {
      const indexTsubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            tipo: 'tsubsis',
            id: idtsubsis,
          }
        }
      )
      if (indexTsubsis > -1) {
        return this.subsisTsubsis[indexTsubsis].value
      }
    },
    clickNetiquetaQR () {
      this.targetFieldQR = 'netiqueta'
      this.showingQR = !this.showingQR
    },
    clickNserieQR () {
      this.targetFieldQR = 'nserie'
      this.showingQR = !this.showingQR
    },
    async decodeQR (value) {
      this.$set(this.form, this.targetFieldQR, value)
    },
  },
}
</script>
