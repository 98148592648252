import _ from '@/utils/lodash'

export default {
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const parteTrabajo = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    return parteTrabajo[0]
  },
  selectTmotivoNofacturable (Vue) {
    const tables = Vue.$offline.db.tables
    const tmotivo = Vue.$offline.tmotivoNofacturable
    return Vue.$offline.db
      .select()
      .from(tables.tmotivo_nofacturable)
      .innerJoin(tmotivo.dummyTable, tmotivo.pk.eq(tmotivo.dummyPk))
      .exec()
  },
  selectFabricantes (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.fabricante.select({
      where: tables.fabricante.estado.gt(0)
    })
  },
  selectAgentesExtintores (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.agenteExtintor.select({
      where: tables.agente_extintor.estado.gt(0)
    })
  },
  selectAgentesPropelentes (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.agentePropelente.select({
      where: tables.agente_propelente.estado.gt(0)
    })
  },
  async selectCodigosSubsis(Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    const subsis = await Vue.$offline.db
      .select()
      .from(tables.subsis)
      .where(
        Vue.$offline.db.op.and(
          tables.subsis.estado.gt(0),
          tables.subsis.idsistema.eq(idsistema),
        )
      )
      .exec()
    return _.map(subsis, 'codigo')
  },
  async selectLstock(Vue, idlparteTrabajo, idarticulo, idparteTrabajo, idalmacen,) {
    const tables = Vue.$offline.db.tables
    const lparteTrabajo = await Vue.$offline.db
      .select()
      .from(tables.lparte_trabajo)
      .where(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.estado.gt(0),
          tables.lparte_trabajo.idarticulo.eq(idarticulo),
          tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.lparte_trabajo.nserie.isNotNull(),
          tables.lparte_trabajo.idlparte_trabajo.neq(idlparteTrabajo)
        )
      )
      .exec()
    const nserieEnLparteTrabajo = _.map(lparteTrabajo, 'nserie')
    const lstock = await Vue.$offline.db
      .select()
      .from(tables.lstock)
      .innerJoin(
        tables.stock,
        tables.stock.idstock.eq(tables.lstock.idstock)
      )
      .innerJoin(
        tables.articulo,
        tables.articulo.idarticulo.eq(tables.stock.idarticulo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.lstock.unidades.gt(0),
          tables.lstock.nserie.isNotNull(),
          tables.stock.idarticulo.eq(idarticulo),
          tables.stock.idalmacen.eq(idalmacen),
        )
      )
      .orderBy(tables.lstock.nserie)
      .exec()
    return _.filter(lstock, (item) => {
      if (!nserieEnLparteTrabajo.includes(item.lstock.nserie)) {
        return item
      }
    })
  },
  async selectFormData (Vue, id) {
    return (await Vue.$offline.lparteTrabajo.row(id)).lparte_trabajo
  },
}
